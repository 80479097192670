@use "/src/styles/table-shared" as *;
@use "/src/styles/palette";

.wf-table {

    &.mat-mdc-table {
        width: 100%;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell,
    .mat-mdc-footer-cell {
        font-size: 12px;
        font-weight: normal;

        padding: $cell-padding-vert $cell-padding-horiz;

        &:first-of-type {
            padding-left: $row-padding-horiz;
        }
        &:last-of-type {
            padding-right: $row-padding-horiz;
        }
    }
    
    .mdc-data-table__row:last-child .mat-mdc-cell {
        // Add the bottom border back the last row
        border-bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
        border-bottom-width: var(--mat-table-row-item-outline-width, 1px);
        border-bottom-style: solid;
    }

    .mat-mdc-header-cell {
        color: palette.$text-light;
    }

    .mat-mdc-cell, .mat-mdc-footer-cell {
        color: black;
    }

    .mat-mdc-cell.wf-column-heading {
        font-weight: $heading-column-weight;
    }

    // Class to support displaying multiple items in a row.
    // Will not allow breaks between items.
    .button-holder {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    // Class to support expandable rows, such as for expanding a child row
    .expandable-row.mdc-data-table__row {
        height: 0;
        border: none;
        > td.mat-mdc-cell {
            padding: 0;
            border: none;
        }
    }
}

mat-table.wf-table {
    .mat-mdc-no-data-row {
        min-height: 48px;
        height: unset;
        
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }

    .mat-mdc-no-data-cell {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        word-wrap: break-word;
        min-height: inherit;
    }
}