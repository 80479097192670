@use "sass:color" as color;
@use "src/styles/palette";

.mat-mdc-table .mat-mdc-row.wf-focusable-row {
    $weight: 97%;
    $hoverColor: black;
    $focusColor: palette.$workfacta-blue;

    transition: background-color 100ms;
    &:hover {
        background-color: color.change($hoverColor, $lightness: $weight);
    }
    &.wf-row-focused {
        background-color: color.change($focusColor, $lightness: $weight);
    }
    &.greenBackground {
        $greenColor: #a6e6a6;
        &:hover {
            background-color: color.mix($greenColor, $hoverColor, $weight: $weight);
        }
        &.wf-row-focused {
            // We need to use a bit more of the focus color to distinguish from the green background
            background-color: color.mix($greenColor, $focusColor, $weight: 85%);
        }
    }

    .wf-focused-row-button {
        transition: opacity 225ms;
        &:not(.wf-focused-row-button-active) {
            opacity: 0;
        }
    }

    &:hover,
    &.wf-row-focused {
        .wf-focused-row-button:not(.wf-focused-row-button-active) {
            opacity: unset;
        }
    }
}

