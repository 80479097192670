.meeting-tabs {
    .mat-mdc-tab-header {
        border: none;
        .mat-mdc-tab-header-pagination {
            box-shadow: none;
        }
    }
    .mat-mdc-tab-labels {
        > div {
            min-width: 160px;
            text-transform: uppercase;
            background: #fff;
            text-align: center;
            padding: 15px 0px 15px 24px;
            margin: 0px;
            color: #9aa9b8;
            font-size: 14px;
            font-weight: 500;
            border: none;
            border-radius: 4px;
            opacity: inherit;
            height: auto;
            flex: 1 1 0;
            border-radius: 0;
            position: relative;
            overflow: visible;
            z-index: auto;
            &:hover {
                background: #13b5ea;
                color: #fff;
                &:not(:last-child):after {
                    background: #13b5ea;
                }
            }
            &:focus {
                background: #13b5ea;
                color: #fff;
            }
            &:not(:last-child):after {
                content: "";
                position: absolute;
                top: 0;
                right: -23px;
                width: 50px;
                height: 100%;
                transform: scale(0.707) rotate(45deg);
                box-shadow: 1px -1px 0 1px #c7c6c6;
                border-radius: 0 0px 0 50px;
                z-index: 9;
                background: #fff;
            }
            .mdc-tab__content {
                max-width: 100%;
            }
            .mdc-tab__text-label {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                transition: none;
                color: inherit;
                position: relative;
                z-index: 99;
                font-size: 12px;
                line-height: inherit;
            }
            
            &:hover, &:focus {
                .mdc-tab__text-label {
                    color: inherit;
                }
            }
        }
        .mdc-tab--active {
            background: #13b5ea;
            color: #fff;

            &:not(:last-child):after {
                background: #13b5ea;
            }
        }
        border: 1px solid #c7c6c6;
        border-radius: 30px;
        overflow: hidden;
    }
    .mdc-tab-indicator__content--underline {
        border: none;
    }
    .mat-mdc-tab-body-content {
        overflow: hidden !important;
    }

    .mdc-tab__ripple::before,
    .mat-mdc-tab .mat-ripple-element, 
    .mat-mdc-tab-header-pagination .mat-ripple-element, 
    .mat-mdc-tab-link .mat-ripple-element {
        background-color: black;
    }
}
@media (max-width: 767px) {
    .meeting-tabs {
        .mat-mdc-tab-labels {
            > div {
                font-size: 12px;
                min-width: 155px !important;
                min-height: 40px;
                .mdc-tab__text-label {
                    font-size: 10px;
                    line-height: 10px;
                }
                &:not(:last-child):after {
                    right: -20px;
                    width: 40px;
                    height: 100%;
                }
            }
        }
        .mat-mdc-tab-header {
            .mat-mdc-tab-header-pagination {
                height: 45px;
                padding: 0;
                min-width: 22px;
            }
        }
    }
}
