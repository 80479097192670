$cell-padding-horiz: 5px;
$cell-padding-vert: 2px;
$row-padding-horiz: 24px;

$heading-column-weight: 600;

@mixin cell-inner-width($inner-width, $max-width: null, $min-width: null) {
    $min-width: if($min-width, $min-width, $inner-width);
    width: calc($inner-width + 2 * $cell-padding-horiz);
    min-width: calc($min-width + 2 * $cell-padding-horiz);
    @if ($max-width) {
        max-width: calc($max-width + 2 * $cell-padding-horiz);
    }

    &:first-of-type,
    &:last-of-type {
        width: calc($inner-width + $row-padding-horiz + $cell-padding-horiz);
        min-width: calc($min-width + $row-padding-horiz + $cell-padding-horiz);
        @if ($max-width) {
            max-width: calc($max-width + $row-padding-horiz + $cell-padding-horiz);
        }
    }
}

@mixin cell-inner-min-width($min-width) {
    min-width: calc($min-width + 2 * $cell-padding-horiz);

    &:first-of-type,
    &:last-of-type {
        min-width: calc($min-width + $row-padding-horiz + $cell-padding-horiz);
    }
}