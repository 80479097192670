.mat-mdc-mini-fab {
    &.small-create,
    &.create-button {
        box-shadow: none !important;
    }
}

.mat-mdc-mini-fab.small-create {
    width: 25px;
    height: 25px;
    line-height: 10px;
    padding: 0px 0px;

    .mat-icon {
        width: 15px;
        height: 15px;
        font-size: 15px;
        line-height: 15px;
        display: block;
    }
}