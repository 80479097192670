@use "sass:color" as color;
@use "/src/styles/palette";

@mixin outline($color) {
    outline: 2px solid $color;
    outline-offset: -2px;
}

tr.mat-mdc-row {
    &.wf-discussion-referred {
        @include outline(color.change($color: palette.$secondary-orange, $alpha: 0.4));
    }
    
    &.wf-discussion-rejected {
        @include outline(palette.$failure-color);
    }

    &.wf-discussion-approved {
        @include outline(palette.$success-color);
    }

    &.wf-discussion-resolved {
        td {
            background-color: color.change($color: palette.$success-color, $alpha: 0.1);
        }
    }
}
