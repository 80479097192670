@use "/src/styles/palette";

.upgrade-footer {
    $width: 250px;
    $icon-height: 160px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;

    .upgrade-feature {
        height: $icon-height;
        width: $width;
    }

    .upgrade-prompt {
        color: palette.$border-color;
        max-width: $width;
        margin: 0;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
    }
}

mat-footer-row.upgrade-footer-row {
    border: none;
    display: block;
}