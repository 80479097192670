@use "src/styles/palette";

.mat-mdc-table {
    font-family: 'Montserrat', sans-serif;
}

html {
    position: relative;
    min-height: 100%;
    background: palette.$background !important;
}

body,
html {
    width: 100%;
    min-height: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px !important;
}

body {
    overflow-x: hidden;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

a:not(.mat-mdc-button-base) {
    text-decoration: none !important;
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
}

a:focus {
    outline: 0px !important;
    text-decoration: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
div,
table {
    font-family: 'Montserrat', sans-serif;
}

img {
    max-width: 100%;
}

body.frontend {
    display: table;
    height: 100%;
    width: 100%;
    background: #0e151c;
    vertical-align: middle;
}

input.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9aa9b8;
}

input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #9aa9b8;
}

input.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #9aa9b8;
}

input.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #9aa9b8;
}

/*RIGHT SECTION css START here ============================== */
.user_img img {
    min-height: 100%;
    max-width: 100%;
    border-radius: 100%;
}

/*--Custom checkbox css start here--*/
.login_box {
    position: relative;
    background: #2b3b4c;
    max-width: 640px;
    margin: 0px auto;
    border-radius: 12px;
    padding: 50px 55px;
    text-align: center;
    color: #fff;
}

.login_sec {
    vertical-align: middle;
    display: table;
    height: 100vh;
    width: 100%;
    padding: 30px 0;
    overflow-y: auto;
}

.login_sec>.container {
    vertical-align: middle;
    display: table-cell;
    height: 100%;
}

.inner_box {
    max-width: 525px;
    margin: 0 auto;
}

.inner_box h1 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 10px;
}

.inner_box .subheading {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    color: #9aa9b8;
}

.login_box .form-control {
    font-size: 16px;
    height: 50px;
    border: none;
    padding: 15px;
    background: #fbf9f9;
    color: #333;
    box-shadow: none;
    transition: ease-in-out .2s;
}

.bluetxt {
    color: #13b5ea !important;
}

.login_box .form-control[type="submit"], .login_box .form-control[type="button"] {
    background: #13b5ea;
    font-size: 21px;
    font-weight: 500;
    padding: 9.5px 20px;
    border-radius: 4px;
    color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
    display: inline-block;
    vertical-align: middle;
    transition: ease-in-out .5s;
    border: none;
    box-shadow: none;
    text-transform: uppercase;
}

.login_box .form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.inner_box form {
    margin: 30px 0 0;
}

.login_box .checkbox {
    font-size: 16px;
    color: #fbf9f9;
    margin-bottom: 25px;
}

a.bluetxt:hover {
    text-decoration: underline;
    color: #13b5ea;
}

.login_box .form-control:focus {
    background: #1f2b38;
    color: #fff;
}

.right_sec {
    margin-left: 0px;
    transition: ease-in-out .5s;
}

*::-webkit-media-controls-panel {
    color: palette.$workfacta-red;
}
.mat-nav-list a {
    font-family: 'Montserrat', sans-serif !important;
}

button:not(.mat-mdc-button-base) {
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
}

/*==========  main content css start here ==========*/

h2.main_heading {
    font-size: 24px;
    color: #2b3b4c;
    margin: 0px 0 15px;
    line-height: 27px;
}

.wht_bgbx {
    background: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.18);
    padding: 5px 9px;
    border-radius: 2px;
}

h3.sub_head {
    font-size: 20px;
    margin: 5px 0 4px;
}

table.table th {
    font-weight: 500;
    color: #606973;
    height: 45px;
    vertical-align: middle !important;
    padding: 5px !important;
}

table.table th i {
    color: #13b5ea;
    width: 15px;
}

.table>thead>tr>th {
    border-bottom: 1px solid #ededed;
    border-top: none;
}

table.table td {
    color: #9aa9b8;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
    overflow: hidden;
    vertical-align: middle !important;
    padding: 5px !important;
    border-top: 1px solid #ededed !important;
    height: 45px;
}

table.table tr:last-child td {
    border-bottom: 1px solid #ededed;
}

section.sec_row {
    margin-bottom: 30px;
}

.row {
    margin-right: -10px !important;
    margin-left: -10px !important;
}

.row>div {
    padding-right: 10px;
    padding-left: 10px;
}

.red {
    color: #ef766d !important;
}

.table {
    margin-bottom: 0;
}

footer {
    background: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);
    padding: 15px;
}

footer {
    background: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);
    padding: 0;
    color: #9aa9b8;
    display: none;
}

button:focus,
input:focus {
    outline: none;
}

form .form-control {
    background: #fafafa;
    border: none;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.18);
    height: 55px;
    padding: 15px 19px;
    transition: ease-in-out .5s;
    color: #2b3b4c;
}

.form-control:focus {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.18) !important;
    outline: none !important;
    background: #fff;
}

.bg_btn {
    padding: 15px 15px !important;
    font-size: 14px !important;
    font-weight: 500;
    text-align: center;
}

.user_img {
    height: 113px;
    width: 113px;
    border-radius: 100%;
    overflow: hidden;

    margin: 0 auto;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.12);
    display: inline-block;
}

.blk_btn {
    width: 100%;
    display: block;
}

.row1 {
    margin-right: -5px;
    margin-left: -5px;
}

.row1>div {
    padding-right: 5px;
    padding-left: 5px;
}

.add_edit .form-group {
    text-align: right;
}

/*Meeting page css start here*/

h3.sub_head select.form-control {
    box-shadow: 0 0 7px rgb(0 0 0 / 22%);
    border: none;
    padding: 8px 10px;
    height: auto;
    max-width: 180px;
    display: inline-block;
    color: #9aa9b8;
    margin-left: 6px;
}

/*--Switch button css start here --*/
span.circle {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    background: #bcbcbc;
    display: block;
    text-align: center;
    font-size: 000px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1098);
    position: absolute;
}


/*--  switch button css start here --*/

.green {
    color: #8ad739 !important;
}

input.form-control.time {
    min-width: 120px;
}

/*----- 16 May css start here------*/
.btn {
    border: none;
    outline: none;
    background-color: #f1f1f1;
    cursor: pointer;
    font-size: 18px;
}

.fa,
.fas,
.far {
    font-weight: 900;
    margin-right: 4px;
    font-size: 14px;
}

/* 6-nov css start here ================ */

[id*=cdk-overlay-] .mat-mdc-tab-link {
    display: block;
    height: auto;
    padding: 16px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-align: left;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0px !important;
    margin: 0px !important;
}

[id*=cdk-overlay-] .mat-mdc-menu-content {
    padding: 0 !important;
}

.mat-datepicker-popup {
    max-width: fit-content !important;
}

/* === 27 May css start here === */
body .mat-mdc-cell,
body .mat-mdc-header-cell {
    word-wrap: unset;
}

.cdk-overlay-pane .mat-mdc-menu-panel {
    width: 100%;
    min-height: auto;

}
.cdk-overlay-pane .mat-mdc-menu-panel.main_menu{
    max-height: calc(100vh - 70px);
}

.d-flex {
    display: flex;
    justify-content: space-between;
}

/*------New css ( Add this only below css ... Not above one this )------*/

.top_row h2 {
    color: #2b3b4c;
    font-size: 18px;
    margin: 0;
    padding: 11px 0;
}

.add_goal {
    font-size: 16px;
    color: #494756;
    vertical-align: middle;
    display: inline-block;
    font-weight: 400;
}

span.value {
    font-weight: 600;
    float: right;
    color: #2b3b4c;
}

.ticker_sec {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    background: #fcfcfc;
    position: relative;
    z-index: 2;
    padding: 14px 20px;
    margin: -15px -15px 0;
    margin-bottom: 5px;
}

.main_content section.sec_row:last-child {
    margin-bottom: 0;
}

.top_row span.value_spn {
    font-weight: 600;
    color: #13b5ea !important;
}

.top_row span.arrow, .top_row .arrow {
    border: none !important;
    border-radius: 100px !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    height: 25px;
    width: 25px;
    color: #13b5ea;
    text-align: center !important;
    position: relative;
    padding: 0;
}

.top_row span.arrow:after, .top_row .arrow:after {
    border-left: 9px solid #13b5ea !important;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    height: 13px !important;
    width: 8px !IMPORTANT;
    position: absolute;
    left: 1px;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%);
}

.top_row span.arrow.left_arrow, .top_row .arrow.left_arrow {
    margin-left: 10px;
}

span.value_spn {
    color: #222;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    min-width: 40px;
}

.ticker_sec .qtr_status {
    margin: 0 15px;
    font-weight: 600;
    color: #13b5ea;
    font-size: 17px;
    
    --mdc-theme-text-primary-on-background: #13b5ea;

    label {
        font-size: 17px;
        line-height: 1.4;
        font-weight: 600;
    }
}

.tblGrid {
    padding: 0px;
    background: #fff;
    height: 400px;
    overflow-y: auto;
    box-shadow: none !important;
    height: calc(100vh - 236px);
    max-height: 100%;
}

span.value_spn {
    color: #222;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    min-width: 40px;
}

span.arrow {
    display: inline-block;
    vertical-align: middle;
    padding: 6px 9px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    line-height: normal;
    cursor: pointer;
}

span.arrow:after {
    content: '2';
    font-size: 0px;
    border-left: 12px solid #666;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    height: 13px;
    width: 13px;
    display: block;
}

span.arrow.left_arrow, .top_row .arrow.left_arrow {
    transform: rotate(180deg);
}

.main_content h2.main_heading {
    padding-top: 4px;
}

/*-------Dashboard css start here ------*/


.demo-content span {
    padding-left: 5px;
}

.demo-content .demo-tab-content {
    background: rgba(204, 204, 204, 0.29);
    padding: 30px 30px;
    border-radius: 4px;
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.32);
}

.chart-container .ngx-charts {
    float: none !important;
}

svg.ngx-charts {
    width: 100% !important;
    height: 240px !important;
    margin-top: -50px !important;
}

/* Chart css end*/

/*--- additional styling--*/

.demo-content .progress {
    margin-bottom: 1em;
}

.demo-content input:valid {
    color: black;
}

.demo-content input:invalid {
    color: palette.$failure-color;
}

.top_row input:not(.mat-mdc-input-element) {
    border: 1px solid #b7b7b7;
    width: auto;
    text-align: center;
    font-weight: 600;
    color: #13b5ea !important;
    font-size: 17px;
    display: inline-block;
    max-width: 58px;
    padding: 0 5px;
    height: auto !important;
    vertical-align: middle;
}

.ticker_sec.top_row {
    font-weight: 600;
    color: #13b5ea;
    font-size: 17px;
}

.mr {
    margin-right: 10px !important;
}

/*---- Dashboard css closed here -----*/

table tr .txt_center {
    text-align: center;
}

.mat-tree .mat-tree-node:first-child button.mat-mdc-icon-button {
    margin-top: 32px;
}

.element-row {
    position: relative;
}

/* .element-row:not(.expanded) {
    cursor: pointer;
} */

.element-row:not(.expanded):hover {
    background: #f5f5f5;
}

.element-row.expanded {
    border-bottom-color: transparent;
}

*:focus,
button:focus,
img:focus,
a:focus {
    outline: none !important;
}

.row1 {
    margin: 0 -5px !important;
}

.row1>div {
    padding: 0 5px !important;
}

.orange {
    color: #f18233;
}

.datePickerBox .mdc-text-field {
    cursor: pointer;
}

/* added to the source element while its mirror is dragged */

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}


/* dragula Css End */

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: palette.$secondary-green;
}
/* custom Dashboard */

.matMenu .mat-icon {
    color: #93a2dd;
}

.matMenu {
    position: absolute;
    right: 11px;
    top: 5px;
}

.matMenu .mat-mdc-icon-button {
    width: 25px;
    height: 25px;
    line-height: 25px;
}

.mat-mdc-header-row {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
}

.permissionsDiv label {
    font-size: 14px;
    font-weight: 600;
}
.div_premission label {
    font-weight: 600;
    font-size: 14px;
}

.valuesPageSec .valuesTitleDiv {
    display: flex;
    margin: 0 -5px 10px !important;
    align-items: center;
}
.valuesPageSec .valuesTitleDiv > div {
    width: auto;
    padding: 0 5px;
}
.values_ContentSec div, .values_ContentSec div span{
    color: #18100e;
    font-size: 15px;
    display: block;
    font-weight: 300;
    line-height: 16px;
}
.editorDiv ul, .editorDiv ol, .values_ContentSec ul, .values_ContentSec ol {
    padding-left: 18px;
}
.values_ContentSec ul br {
    display: none;
}
.cdk-overlay-container .cdk-overlay-pane:not(.mat-mdc-tooltip-panel):not(.wf-template-tooltip-panel) {
    transform: none !important;
}
.frontend, .frontend .login_sec {
    background: #0E151D;
}
.ticker_sec .loadDataTxt{
   color: palette.$failure-color !important;
   margin-left: 10px;
}
.lightBlackColor{
    background:palette.$secondary-gray !important;
}
.greenColor{
    background:palette.$success-color !important;
}
.redColor {
    color:palette.$failure-color !important;
}
.blueColorIcon{
    color: rgba(2, 166, 242, 0.88) !important;
}
.displayBlock{
    display: block;
}
.displayContents{
    display: contents;
}

body button {
    font-weight: 500;
}

.mat-ripple:not(:empty) {
    transform: none !important;
}
.top_row input:not(.mat-mdc-input-element) {
    box-shadow: none !important;
    outline: none !important;
}

.main_heading button, .sub_head button{
    background: none;
    border: none;
}
/* === media CSS Start here === */
[app-disabled] {
    opacity: 0.9;
    cursor: not-allowed !important;
    outline: none !important;
}
button{
    background: none;
    border: none;
}
i[app-disabled]{
    pointer-events: none;
}
mat-form-field[app-disabled]{
    pointer-events: none;
}
.formStatusSelect[app-disabled]{
    pointer-events: none;
}
mat-cell[app-disabled]{
    pointer-events: none;
}
mat-select[app-disabled]{
    pointer-events: none;
}
span[app-disabled],a[app-disabled]{
    pointer-events: none;
}
.editorDiv[app-disabled]{
    pointer-events: none;
}
.dis-contents{
    display: contents;
}
.formTextarea .cdk-tree-node:nth-child(2) {
    padding: 0 !important;
}
.formTextarea .cdk-tree-node:nth-child(2) h3.sub_head {
    margin-left: 40px;
}
.formTextarea .cdk-tree-node:nth-child(2) .editorDiv, .formTextarea .cdk-tree-node:nth-child(2) .tox-tinymce  {
    margin-left: 40px;
}

.formTextarea .cdk-tree-node:nth-child(3) {
    padding: 0 !important;
}
.formTextarea .cdk-tree-node:nth-child(3) h3.sub_head {
    margin-left: 80px;
}
.formTextarea .cdk-tree-node:nth-child(3) .editorDiv, .formTextarea .cdk-tree-node:nth-child(3) .tox-tinymce  {
    margin-left: 80px;
}
.tox-notifications-container {
    max-width: 550px !important;
}

.datePickerBox input.mat-mdc-input-element{
    cursor: pointer;
}
.tox-tinymce{
    min-height: 200px;
    max-height: calc(100vh - 320px) !important;
}
.orangeOutline {
    outline: 2px solid #FFC63980;
    outline-offset: -2px;
}
.redOutline {
    outline: 2px solid #E7004CC0;
    outline-offset: -2px;
}
.greenBackground {
    background-color: #a6e6a6
}
.no-records{
    padding-left: 10px;
}

@media (max-width:1600px) {
    .login_box {
        max-width: 630px;
        padding: 50px;
    }
    .login_box .form-control {
        font-size: 15px;
    }
    .form-group {
        margin-bottom: 10px;
    }
    .inner_box .subheading {
        font-size: 17px;
    }
    .inner_box h1 {
        font-size: 26px;
    }
    table.table th {
        font-size: 12px;
    }
    table.table th i {
        width: 11px;
    }
    .bg_btn {
        min-width: 147px;
    }
}

@media (max-width:1500px) {
    .nav-tabs>li a {
        min-width: 134px;
    }
}

@media (max-width:1320px) {
    .right_sec {
        margin-left: 0;
    }
}

@media (max-width:1199.98px) {
    .login_box {
        max-width: 630px;
        padding: 50px;
    }
    .login_box .form-control {
        font-size: 15px;
    }
    .form-group {
        margin-bottom: 10px;
    }
    .inner_box .subheading {
        font-size: 17px;
    }
    .inner_box h1 {
        font-size: 26px;
    }
    table.table th {
        font-size: 12px;
    }
    table.table th i {
        width: 11px;
    }
    .bg_btn {
        min-width: 147px;
    }
}

@media (max-width:1500px) {
    .nav-tabs>li a {
        min-width: 134px;
    }
}

@media (max-width:1125px) {
    .demo-content .demo-tab-content {
        padding: 15px !important;
    }
    .margin-15 div#printDiv {
        background: #f8f8f8;
        overflow-x: auto;
    }
    .margin-15 div#printDiv > table {
        min-width: 775px;
    }
    .nav-tabs>li a {
        min-width: 105px;
    }
    ul.nav.nav-tabs {
        overflow-x: auto;
        display: flex;
    }
}

@media (max-width: 1024.98px) {
    .loadDataTxt {
        display: block;
        margin: 0 !important;
    }
    .login_box {
        max-width: 575px;
        padding: 35px 45px;
    }
    .inner_box img {
        max-width: 360px;
    }
}

@media (max-width:767px) {
    .login_box {
        padding: 25px 14px;
        max-width: 439px;
    }
    .login_box .inner_box img{
        max-width: 215px !important;
        width: 100%;
    }
    .inner_box h1 {
        font-size: 22px;
        line-height: 29px;
    }
    .inner_box .subheading {
        font-size: 14px;
    }
    .loginBtnCls {
        font-size: 16px !important;
    }
    .login_box .form-control {
        font-size: 14px;
        height: 42px;
        padding: 10px 14px;
    }
    .login_box .form-control[type="submit"], .login_box .form-control[type="button"] {
        font-size: 13px;
        padding: 6px 10px;
    }
    .right_sec {
        margin-left: 0px !important;
    }
    .main_content {
        padding: 30px 14px 50px;
    }
    h2.main_heading {
        font-size: 18px;
        margin: 0px 0 10px;
        line-height: 24px;
    }
    .main_content h2.main_heading {
        padding-top: 2px;
    }
    h3.sub_head {
        font-size: 16px;
        margin: 5px 0 9px;
    }
    footer {
        font-size: 12px;
        line-height: normal;
        padding: 15px;
    }
    .table-responsive {
        border: none;
    }
    .add_edit .user_img {
        height: 75px;
        width: 75px;
        border: 3px solid #ffffff;
    }
    .add_edit .user_img img {
        max-width: 100%;
    }
    .add_edit .form_btn {
        text-align: left;
    }
    .form-horizontal .control-label {
        padding: 0 0px;
    }
    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-icon-button {
        font-size: 12px;
        line-height: normal !important;
    }
    .main_content {
        padding: 0px !important;
    }
    .demo-content .demo-tab-content {
        padding: 10px !important;
    }
    .login_sec {
        padding: 20px 0;
        position: static;
        height: 100vh;
    }
    .inner_box form {
        margin: 25px 0 0px;
    }
    table.table th {
        font-size: 11px;
    }
    .fa, .fas, .far {
        font-size: 13px;
    }
    .bg_btn {
        font-size: 11px !important;
    }
    .displayBlock canvas {
        margin: 15px 0;
    }
    .top_row input:not(.mat-mdc-input-element) {
        max-width: 50px;
    }
    .login_box .mat-mdc-form-field-error {
        line-height: 10px;
    }
    .add_goal {
        font-size: 13px;
    }
}

@media (max-width:767px) {
    body app-header {
        order: 1;
    }
    .demo-content .demo-tab-content {
        padding: 30px 15px;
    }
    .demo-content h4 {
        font-size: 19px;
        line-height: normal;
    }
    h4 {
        font-size: 17px !important;
    }
    .top_row span.value_spn {
        padding: 0 3px;
        min-width: 32px;
    }
    .bg_btn {
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }
    .cdk-overlay-pane .mat-mdc-menu-panel.main_menu {
        max-height: calc(100vh - 150px);
    }
    .tox-notifications-container {
        max-width: 400px!important;
    }
}
@media (max-width:575px) {
    .tox-notifications-container {
        max-width: 280px!important;
    }
}
@media (max-width:375px) {
    .tox-notifications-container {
        max-width: 220px!important;
    }
}

@media print {
    body * {
        visibility: hidden;
        background: #f5faff !important;
        width: 100% !important;
    }
    .cdk-overlay-container {
        z-index: 0;
    }
}
