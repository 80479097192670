@use "sass:color";
@use "/src/styles/palette";

html {
    --wf-status-indicator-outline-width: 0.1em;
}

.wf-status-indicator {
    display: inline-block;
    width: auto;
    padding: 0 0.33em;
    font-weight: 600;
    border-radius: 0.33em;

    color: var(--wf-status-indicator-color);

    &.wf-status-indicator-outline {    
        outline: var(--wf-status-indicator-outline-width) solid currentColor;
        outline-offset: calc(var(--wf-status-indicator-outline-width) * -1);
    }

    &.wf-status-indicator-shaded {
        background-color: var(--wf-status-indicator-shade-color);
    }

    &.wf-status-indicator-filled {
        color: white;
        background-color: var(--wf-status-indicator-color);
    }
}

@mixin status-indicator-color($color) {
    --wf-status-indicator-color: #{$color};
    --wf-status-indicator-shade-color: #{color.change($color, $alpha: 0.15)};
}

html, .wf-status-indicator-neutral {
    @include status-indicator-color(palette.$text-light);
}

.wf-status-indicator-intermediate {
    @include status-indicator-color(palette.$secondary-orange);
}

.wf-status-indicator-success {
    @include status-indicator-color(palette.$success-color);
}

.wf-status-indicator-failure {
    @include status-indicator-color(palette.$failure-color);
}