.wf-dialog-container {
    .mat-mdc-dialog-content {
        .mat-mdc-form-field {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        
        // The content normally removes padding from the first and last children,
        // but in the case an outlined form field is the first child of the content element.
        // we need to keep the padding, as the floating label overflows the form element.
        & > .mat-mdc-form-field.mat-form-field-appearance-outline:first-child {
            margin-top: 5px;
        }
    
        > table.wf-table {
            margin: 0 -24px;
            width: calc(100% + 48px);
        }
    }
}