@use "sass:math" as math;
@use "src/styles/palette";

.mat-mdc-form-field-icon-suffix .mat-icon.feature-stars {
    $icon-size: 16px;
    height: $icon-size;
    width: $icon-size;
    font-size: $icon-size;
}

.mat-mdc-tooltip.feature-tooltip .mdc-tooltip__surface {
    // This tooltip is designed to have an arrow pointing towards the element it came from.
    // To do this, it overlaps pseudo-elements that have one non-transparent border, 
    // which renders as a triangle pointing away from the tooltip.

    $tooltip-arrow-width: 10px;
    $tooltip-body-color: white;
    $border-color: palette.$failure-color;
    $border-width: 3px;

    // The arrow border is on 45 degrees, so multiply it by root 2 to get the vertical/horizontal width.
    $arrow-border-width: $border-width * math.pow(2, .5);

    padding: 8px 16px;

    border: $border-width solid $border-color;
    border-radius: 16px;
    background-color: $tooltip-body-color;
    color: black;

    font-size: 12px;
    font-weight: 500;

    overflow: visible;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent
    }

    $inner-width: $tooltip-arrow-width;
    // Slightly larger than the inner arrow
    $outer-width: $tooltip-arrow-width + $arrow-border-width;
  
    // The inner part of the arrow - same color as the container background.
    &:after {
        border-width: $inner-width;
    }
  
    // The border of the arrow - same color as the container background.
    &:before {
        border-width: $outer-width;

        // Angular Material has some default styles that we need to reset.
        border-radius: unset;
        left: unset;
        top: unset;
    }

    .mat-mdc-tooltip-panel-right &, 
    .mat-mdc-tooltip-panel-left & {
        &:after {
            // Half way down, minus the element border width
            top: calc(50% - #{$inner-width});
        }

        &:before {
            // Half way down, minus the element border width
            top: calc(50% - #{$outer-width});
        }
    }

    .mat-mdc-tooltip-panel-before &, 
    .mat-mdc-tooltip-panel-after & {
        &:after {
            // Half way down, minus the element border width
            inset-block-start: calc(50% - #{$inner-width});
        }

        &:before {
            // Half way down, minus the element border width
            inset-block-start: calc(50% - #{$outer-width});
        }
    }

    .mat-mdc-tooltip-panel-above &,
    .mat-mdc-tooltip-panel-below & {
        &:after {
            // Half way across, minus the element border width
            left: calc(50% - #{$inner-width});
        }

        &:before {
            // Half way across, minus the element border width
            left: calc(50% - #{$outer-width});
        }
    }

    .mat-mdc-tooltip-panel-right & {
        &:after,
        &:before {
            right: 100%;
        }

        &:after {
            border-right-color: $tooltip-body-color;
        }

        &:before {
            border-right-color: $border-color;
        }
    }

    .mat-mdc-tooltip-panel-left & {
        &:after,
        &:before {
            left: 100%;
        }

        &:after {
            border-left-color: $tooltip-body-color;
        }

        &:before {
            border-left-color: $border-color;
        }
    }

    .mat-mdc-tooltip-panel-before & {
        &:after,
        &:before {
            inset-inline-start: 100%;
        }

        &:after {
            border-inline-start-color: $tooltip-body-color;
        }

        &:before {
            border-inline-start-color: $border-color;
        }
    }

    .mat-mdc-tooltip-panel-after & {
        &:after,
        &:before {
            inset-inline-end: 100%;
        }

        &:after {
            border-inline-end-color: $tooltip-body-color;
        }

        &:before {
            border-inline-end-color: $border-color;
        }
    }

    .mat-mdc-tooltip-panel-above & {
        &:after,
        &:before {
            top: 100%;
        }

        &:after {
            border-top-color: $tooltip-body-color;
        }

        &:before {
            border-top-color: $border-color;
        }
    }

    .mat-mdc-tooltip-panel-below & {
        &:after,
        &:before {
            bottom: 100%;
        }

        &:after {
            border-bottom-color: $tooltip-body-color;
        }

        &:before {
            border-bottom-color: $border-color;
        }
    }
}