@use "/src/styles/mixins";
@use "/src/styles/palette";

button.mdc-button {
    &.round-button,
    &.add-button {
        border-radius: 18px; // half of the line-height

        &.mat-unthemed {
            --mdc-text-button-label-text-color: #{palette.$text-light};
        }

        mat-icon {
            @include mixins.mat-icon-size(24px);
        }
    }
}