@use "@angular/material" as mat;
@use "/src/styles/mat-palette";
@use "/src/styles/variables";
@use "theme-helpers";

$primary: mat.m2-define-palette(mat-palette.$md-wf-blue, 500);
$accent: mat.m2-define-palette(mat-palette.$md-sec-green, 500);
$warn: mat.m2-define-palette(mat-palette.$md-wf-red, 500);
$colors: (
    primary: $primary, 
    accent: $accent, 
    warn: $warn,
);

@function typography-level($font-size, $font-weight: null) {
    @return mat.m2-define-typography-level(
        $font-size: $font-size,
        $line-height: 1.42857143,
        $font-weight: $font-weight,
    );
}

$custom-typography: mat.m2-define-typography-config(
    $font-family: 'Montserrat, sans-serif',
    $button: typography-level(14px, 500),
    $body-1: typography-level(14px),
    $body-2: typography-level(14px),
    $caption: typography-level(12px),
);

@include mat.elevation-classes();
@include mat.app-background();

$theme: mat.m2-define-light-theme((
    color: $colors,
    typography: $custom-typography,
));

// Default Theme
@include mat.all-component-themes($theme);

@include theme-helpers.fix-button-contrast($colors);
@include theme-helpers.apply-aria-disabled($theme);

.wf-theme {
    --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.32);
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
}

.wf-theme-dark {
    $dark-theme: mat.m2-define-dark-theme((
        color: $colors,
    ));
    @include mat.all-component-colors($dark-theme);
    @include theme-helpers.fix-button-contrast($colors);
    @include theme-helpers.apply-aria-disabled($dark-theme);

    & {
        --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.87);
    }
}

.wf-theme, .wf-theme-dark {
    $field-radius: variables.$border-radius;
    
    // Many field components (such as mat-select) will expect to be at least 24px tall. As such, if we leave the line height
    // as the default value, we will find these no longer align with standard text inputs (which end up as 20px tall).
    --mat-form-field-container-text-line-height: 24px;
    
    --mdc-outlined-text-field-container-shape: #{$field-radius};
    --mdc-filled-text-field-container-shape: #{$field-radius};
    --mat-standard-button-toggle-shape: #{$field-radius};
    --mdc-dialog-container-shape: #{$field-radius};
    --mdc-elevated-card-container-shape: #{$field-radius};
    --mdc-outlined-card-container-shape: #{$field-radius};

    --mat-menu-item-label-text-weight: 500;

    --mat-dialog-container-small-max-width: 90vw;
    --mat-dialog-content-padding: 16px 24px;
    // Note: the touch target of a checkbox is larger than the space they take up.
    // This means if they are the last element in the dialog content, the content will scroll.
    // To prevent this, we ensure we always have padding on the bottom of the content, and reduce the padding on the actions to accommodate.
    --mat-dialog-with-actions-content-padding: 16px 24px 8px;
    --mat-dialog-actions-padding: 8px 24px 16px;

    .mdc-text-field--filled {
        border-radius: var(--mdc-filled-text-field-container-shape);

        .mdc-line-ripple {
            display: none;
        }
    }

    .mat-mdc-checkbox label {
        font-weight: normal;
    }

    .mat-form-field-appearance-outline.no-label {
        .mat-mdc-text-field-wrapper {
            padding: 0 0.75em;
        }

        .mat-mdc-form-field-infix {
            padding: 8px 0;
            min-height: 40px;
        }
    }

    .mat-mdc-button,
    .mat-mdc-unelevated-button {
        .mat-mdc-select {
            width: auto;
            &,
            .mat-mdc-select-value,
            .mat-mdc-select-arrow {
                color: inherit;
            }
        }
    }

    .mat-mdc-tooltip .mdc-tooltip__surface {
        font-size: 13px;
        padding: 5px 10px;
        text-align: center;
    }
}

.wf-theme-dark {
    --mdc-filled-text-field-container-color: white;
    --mdc-filled-text-field-disabled-container-color: #fafafa;
    --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
    --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
    --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
    --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);

    --mat-select-panel-background-color: white;
    --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
    --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
    --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
}