@use 'variables-legacy' as *;

/******************* 
Global Styles 
*******************/

* {
    outline: none;
    box-sizing:border-box;
}

body {
    
    margin: 0;
    overflow-x: hidden;
    color: $bodytext;
    background:$bodycolor;
}

html {
    position: relative;
    min-height: 100%;
    background: $white;
}
a:hover, a:focus, a{
    text-decoration: none;
}
a.mat-mdc-button:hover, a.mat-button:focus {
    color: inherit;
}
a.link{
    color:$headingtext;
    text-decoration: none;
    &:hover, &:focus{
        color:$themecolor;
    }
}
.img-responsive{
    width: 100%;
    height: auto;
    display: inline-block;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-rounded{
    border-radius: $radius;
}
html body .mdi:before, html body .mdi-set{
    line-height: initial;
}
/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

h1 {
    line-height: 40px;
    font-size: 36px
}

h2 {
    line-height: 36px;
    font-size: 30px
}

h3 {
    line-height: 30px;
    font-size: 21px
}

h4 {
    line-height: 22px;
    font-size: 18px
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}  
.box{
    border-radius: $radius;
    padding: 10px;
}
html body {
    .dl{
        display: inline-block;
    }
}    
.d-flex{
    display: flex;
 }
/*******************
Blockquote
*******************/

html body blockquote {
    
    border: 1px solid $border;
    border-left: 5px solid $themecolor;
    padding: 15px;
    margin: 20px 0;
}
ol li{
    margin: 5px 0;
}
/*******************
Text Colors
*******************/
.text-muted {
    color: $muted!important;
}
html body {
    .text-center{
        text-align: center;
    }
    .text-right{
        text-align: right;
        
    }
    .text-left{
        text-align: left;
    }
}
hr{
    border: 0px;
    height:1px;
    background: $border;
}
/*******************
Rounds
*******************/
.round{
    line-height:48px;
    color: $white;
    width: 45px;
    height: 45px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: $info;
    img{
        border-radius: 100%;
    }
}
/*******************
Labels
*******************/

.label {
    padding: 3px 10px;
    line-height: 13px;
    color: $white;
    font-weight: 400;
    border-radius: $radius;
    font-size: 75%;
}


/*******************
forms
*******************/
.form-control{
    border: 0px;
    line-height: 25px;
    padding: 10px;
    width: 100%;
    font-size:$font-16;
}
.form-control.mat-mdc-input-element {
    box-sizing: border-box;
}
.mat-mdc-form-field{
    width: 100%;
}

/*******************
Grid
*******************/
.row{
    margin: 0 -10px;
}
/*******************
Table
*******************/
.table{
    width: 100%;
    text-align: left;
}
.table-rasponsive{
    overflow:auto;
    .mat-mdc-table{
        min-width: 280px;
    }
    .mat-mdc-header-row{
        .mat-mdc-header-cell{
            white-space: nowrap;
        }
    }
    .mat-mdc-cell{
            white-space: nowrap;
        }
}
/*******************
Sparkline chart tooltip
*******************/
.jqstooltip{
  width: auto !important;
  height: auto !important;
  background: $inverse;
  color: $white;
  padding: 5px 15px; 
  border: 0px;
  border-radius: 60px;     
}
/*******************
list inline
*******************/
.list-inline{
    margin-left: 0px;
    padding: 0px;
    li{
        display: inline-block;
        list-style: none;
        margin-right: 10px;
        &:last-child{
            margin-right: 0px;
        }
    }
}

.loading-overlay, .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-overlay {
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
}

.multiline-description {
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow-y: auto;
    overflow-x: hidden;
    overflow-wrap: break-word;
    min-width:inherit;
}
