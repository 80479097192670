@use 'sass:map';
@use 'sass:color';
@use "@angular/material" as mat;

@mixin fix-button-contrast($colors) {
    .mat-mdc-unelevated-button {
        @each $color, $palette in $colors {
            &.mat-#{$color} {
                --mdc-filled-button-label-text-color: #{mat.m2-get-color-from-palette($palette, 'default-contrast')};
            }
        }
    }

    .mat-mdc-raised-button {
        @each $color, $palette in $colors {
            &.mat-#{$color} {
                --mdc-protected-button-label-text-color: #{mat.m2-get-color-from-palette($palette, 'default-contrast')};
            }
        }
    }

    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        @each $color, $palette in $colors {
            &.mat-#{$color} {
                --mdc-fab-icon-color: #{mat.m2-get-color-from-palette($palette, 'default-contrast')};
                --mat-mdc-fab-color: #{mat.m2-get-color-from-palette($palette, 'default-contrast')};
            }
        }
    }

    .mat-mdc-checkbox {
        @each $color, $palette in $colors {
            &.mat-#{$color} {
                --mdc-checkbox-selected-checkmark-color: #{mat.m2-get-color-from-palette($palette, 'default-contrast')};
            }
        }
    }
}

@mixin apply-aria-disabled($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $is-dark: mat.get-theme-type($theme) == dark;

    $on-surface: if($is-dark, #fff, #000);
    $disabled-container-color: rgba($on-surface, 0.12);
    $disabled-label-text-color: rgba($on-surface, if($is-dark, 0.5, 0.38));

    .mat-mdc-button {
        &[aria-disabled=true]:not(:disabled) {
            --mat-text-button-state-layer-color: #{$disabled-label-text-color};
            --mat-text-button-ripple-color: #{color.change($disabled-label-text-color, $alpha: 0.1)};

            color: var(--mdc-text-button-disabled-label-text-color);
        }
    }

    .mat-mdc-unelevated-button {
        &[aria-disabled=true]:not(:disabled) {
            --mdc-filled-button-disabled-container-color: #{$disabled-container-color};
            --mdc-filled-button-disabled-label-text-color: #{$disabled-label-text-color};
            --mdc-filled-button-container-color: #{$disabled-container-color};
            --mdc-filled-button-label-text-color: #{$disabled-label-text-color};

            color: var(--mdc-filled-button-disabled-label-text-color);
            background-color: var(--mdc-filled-button-disabled-container-color);
        }
    }

    .mat-mdc-raised-button {
        &[aria-disabled=true]:not(:disabled) {
            --mdc-protected-button-disabled-container-color: #{$disabled-container-color};
            --mdc-protected-button-disabled-label-text-color: #{$disabled-label-text-color};
            --mdc-protected-button-container-color: #{$disabled-container-color};
            --mdc-protected-button-label-text-color: #{$disabled-label-text-color};
            --mdc-protected-button-container-elevation: 0;

            box-shadow: none;
            color: var(--mdc-protected-button-disabled-label-text-color);
            background-color: var(--mdc-protected-button-disabled-container-color);
        }
    }

    .mat-mdc-outlined-button {
        &[aria-disabled=true]:not(:disabled) {
            --mdc-outlined-button-label-text-color: #{$disabled-label-text-color};
            --mdc-outlined-button-disabled-label-text-color: #{$disabled-label-text-color};
            --mdc-outlined-button-outline-color: #{$disabled-container-color};
            --mdc-outlined-button-disabled-outline-color: #{$disabled-container-color};

            color: var(--mdc-outlined-button-disabled-label-text-color);
            background-color: var(--mdc-outlined-button-disabled-container-color);
        }
    }

    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        &[aria-disabled=true]:not(:disabled) {
            --mdc-fab-container-color: #{$disabled-container-color};
            --mdc-fab-icon-color: #{$disabled-label-text-color};
            --mat-mdc-fab-color: #{$disabled-label-text-color};
            
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
        }
    }

    .mat-mdc-icon-button {
        &[aria-disabled=true]:not(:disabled) {
            --mdc-icon-button-icon-color: #{$disabled-label-text-color};
            --mdc-icon-button-disabled-icon-color: #{$disabled-label-text-color};
            --mat-icon-button-state-layer-color: #{$disabled-label-text-color};
            --mat-icon-button-ripple-color: #{color.change($disabled-label-text-color, $alpha: 0.1)};
            color: var(--mdc-icon-button-disabled-icon-color);
        }
    }
}