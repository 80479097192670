@use "variables-legacy" as *;

/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/*============================================================== 
 For all pages 
 ============================================================== */

/*******************
 Main container
*******************/

.main-container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mat-drawer-container {
    background: $bodycolor;
}

html .page-wrapper {
    .page-content {
        padding: 15px;
    }
}

.error-card {
    h1 {
        font-size: 210px;
        font-weight: 900;
        line-height: 210px;
        margin: 0px;
        text-shadow: 4px 5px 1px #b3bbbf;
    }
}
