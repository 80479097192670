@use "/src/styles/palette";
@use "/src/styles/variables";

.wf-section {
    position: relative;
    border: 1px solid palette.$border-color;
    border-radius: variables.$border-radius;
    padding: variables.$section-padding;

    h4.wf-section-heading {
        margin: 0;
        font-size: 1.2em;
        font-weight: bold;
    }

    h4.wf-section-heading-legend {
        position: absolute;
        width: auto;
        top: -8px; // Half the line height
        left: variables.$border-radius;
        margin: 0;
        padding: 0 5px;

        font-size: 12px;
        line-height: 16px;
        background-color: white;
    }
}