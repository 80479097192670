@use "src/styles/palette";

.plan-exceeded-header {
    background-color: palette.$failure-color;
    color: white;
    padding: 4px 10px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    border-radius: 14px; // half of the height plus padding
    cursor: pointer;

    .plan-exceeded-action {
        text-decoration: underline;
    }
}