@use "/src/styles/palette";

.mat-mdc-chip.mat-mdc-standard-chip.small-chip {
    --mdc-chip-label-text-size: 10px;
    height: 24px;

    .mdc-evolution-chip__action--primary {
        padding-left: 8px;
        padding-right: 8px;
    }
}