mat-checkbox,
mat-radio-button,
mat-slide-toggle {
    label {
        margin-bottom: 0;
        font-weight: 500;
    } 
}

mat-expansion-panel-header > .mat-expansion-indicator {
    line-height: normal;
}

mat-form-field label.mdc-floating-label {
    font-weight: inherit;
    max-width: none;
}

a.mat-mdc-icon-button {
    &:hover,
    &:focus {
        color: var(--mdc-icon-button-icon-color);
    }
}