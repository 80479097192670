/**
 * Colors in this file should be synchronised with src/app/shared/colors.ts
 */

$workfacta-navy: #243746;
$workfacta-blue: #3CB4E5;
$workfacta-yellow: #FFC629;
$workfacta-red: #E7004C;

$secondary-gray: #758592;
$secondary-navy: #0B223F;
$secondary-blue: #003DA6;
$secondary-green: #00AE42;
$secondary-orange: #FF8300;
$secondary-red: #FE3B1F;

$text-light: #808080;

$background: #eef5f9;
$border-color: #C8C8C8;
$header-color: #2b3b4c;

$success-color: $secondary-green;
$failure-color: $workfacta-red;

$sidebar-divider-color: #213141;