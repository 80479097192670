@use "/src/styles/palette";

.mat-mdc-chip.mat-mdc-standard-chip.filter-chip,
.mat-mdc-chip.mat-mdc-standard-chip.small-filter-chip {
    background: none;
    border: 2px solid palette.$border-color;

    --mdc-chip-label-text-color: #{palette.$workfacta-blue};
    --mdc-chip-disabled-label-text-color: #{palette.$workfacta-blue};
    --mdc-chip-with-icon-icon-color: #{palette.$workfacta-blue};
    --mdc-chip-with-icon-disabled-icon-color: #{palette.$workfacta-blue};
    --mdc-chip-with-icon-selected-icon-color: #{palette.$workfacta-blue};
}

.mat-mdc-chip.mat-mdc-standard-chip.small-filter-chip {
    margin-top: 0;
    margin-bottom: 0;
    min-width: 0;

    --mdc-chip-container-height: 24px;
    --mdc-chip-container-shape-radius: 12px;
    --mdc-chip-label-text-size: 12px;
    --mdc-chip-with-avatar-avatar-size: 16px;

    .mdc-evolution-chip__cell {
        min-width: 0;
    }

    .mdc-evolution-chip__action {
        max-width: 100%;
    }

    .mat-mdc-chip-action-label {
        overflow: hidden;
    }

    .mdc-evolution-chip__action--primary {
        padding-left: 8px;

        .mat-icon {
            height: 16px;
            width: 16px;
            font-size: 16px;
        }
    }

    &.mdc-evolution-chip--with-primary-graphic,
    &.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic {
        .mdc-evolution-chip__graphic {
            padding-left: 8px;
            padding-right: 4px;
        }
    }

    .mdc-evolution-chip__icon--trailing {
        &, & .mat-icon {
            height: 12px;
            width: 12px;
            font-size: 12px;
        }
    }

    &.mdc-evolution-chip--with-trailing-action,
    &.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action,
    &.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action {
        .mdc-evolution-chip__action--trailing {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}