@use "/src/styles/palette";

.mat-mdc-tooltip.light-tooltip .mdc-tooltip__surface {
    max-width: none;
    padding: 0;

    background-color: white;
    border: 1px solid palette.$border-color;
    color: palette.$text-light;
    font-size: 13px;
}