/**
 * Palette generated using http://mcg.mbitson.com/#!?mcgpalette0=%23e7004c&themename=mcgtheme
 * Palette source color: #E7004C
 */
$md-wf-red: (
    50 : #fce0ea,
    100 : #f8b3c9,
    200 : #f380a6,
    300 : #ee4d82,
    400 : #eb2667,
    500 : #e7004c,
    600 : #e40045,
    700 : #e0003c,
    800 : #dd0033,
    900 : #d70024,
    A100 : #fffefe,
    A200 : #ffcbd1,
    A400 : #ff98a3,
    A700 : #ff7f8c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/**
 * Palette generated using http://mcg.mbitson.com/#!?mcgpalette0=%233cb4e5
 * Palette source color: #3CB4E5
 */
$md-wf-blue: (
    50 : #e8f6fc,
    100 : #c5e9f7,
    200 : #9edaf2,
    300 : #77cbed,
    400 : #59bfe9,
    500 : #3cb4e5,
    600 : #36ade2,
    700 : #2ea4de,
    800 : #279cda,
    900 : #1a8cd3,
    A100 : #ffffff,
    A200 : #d4edff,
    A400 : #a1d8ff,
    A700 : #87ceff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/**
 * Palette generated using http://mcg.mbitson.com/#!?mcgpalette0=%2300ae42
 * Palette source color: #00AE42
 */
$md-sec-green: (
    50 : #e0f5e8,
    100 : #b3e7c6,
    200 : #80d7a1,
    300 : #4dc67b,
    400 : #26ba5e,
    500 : #00ae42,
    600 : #00a73c,
    700 : #009d33,
    800 : #00942b,
    900 : #00841d,
    A100 : #b1ffbc,
    A200 : #7eff90,
    A400 : #4bff65,
    A700 : #31ff4f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);