@use "sass:color";

@mixin mat-icon-size($size) {
    height: $size;
    width: $size;
    font-size: $size;
    line-height: $size;
}

@mixin mat-icon-size-relative($size) {
    font-size: $size;
    height: 1em;
    width: 1em;
    line-height: 1em;
}

@mixin mat-icon-button-size($button-size) {
    $icon-size: $button-size * 0.6;
    
    height: $button-size;
    width: $button-size;
    line-height: $button-size;
    padding: calc(($button-size - $icon-size) / 2);

    .mat-icon {
        @include mat-icon-size($icon-size);
        display: block;
    }
}

@mixin mat-button-color($color) {
    --mdc-text-button-label-text-color: #{$color};
    --mat-text-button-state-layer-color: #{$color};
    --mat-text-button-ripple-color: #{color.change($color, $alpha: 0.1)};
}

@mixin mat-flat-button-color($color, $text-color: null) {
    --mdc-filled-button-container-color: #{$color};
    @if ($text-color) {
        --mdc-filled-button-label-text-color: #{$text-color};
        --mat-filled-button-state-layer-color: #{$text-color};
        --mat-filled-button-ripple-color: #{color.change($text-color, $alpha: 0.1)};
    }
}

@mixin mat-raised-button-color($color, $text-color: null) {
    --mdc-protected-button-container-color: #{$color};
    @if ($text-color) {
        --mdc-protected-button-label-text-color: #{$text-color};
        --mat-protected-button-state-layer-color: #{$text-color};
        --mat-protected-button-ripple-color: #{color.change($text-color, $alpha: 0.1)};
    }
}

@mixin mat-icon-button-color($color) {
    $disabled-color: color.change($color, $alpha: 0.38);
    --mdc-icon-button-icon-color: #{$color};
    --mdc-icon-button-disabled-icon-color: #{$disabled-color};
    --mat-icon-button-state-layer-color: #{$color};
    --mat-icon-button-ripple-color: #{color.change($color, $alpha: 0.1)};

    &:disabled, &[aria-disabled=true] {
        --mdc-icon-button-icon-color: #{$disabled-color};
        --mdc-icon-button-disabled-icon-color: #{$disabled-color};
    }
}
