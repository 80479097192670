.wf-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ticker_sec,
    .header-row {
        flex: 0 0 auto;
    }
}

.wf-page-body {
    flex: 1 1 0;
    border-radius: 15px;
    overflow: auto;
    background: white;
}