@use "/src/styles/variables";

.mat-form-field-appearance-outline.mat-form-field-narrow {

    // The form field is normally 56px tall with 16px padding.
    // Reducing the height to 38px means we must reduce the padding by 9px to 7px
    --mat-form-field-container-height: 38px;
    --mat-form-field-container-vertical-padding: 7px;

    .mat-mdc-form-field-infix {
        // We want to slightly shift down the contents of the field to ensure they are vertically centered.
        padding-top: 8px;
        padding-bottom: 6px;
    }
}

.mat-form-field-appearance-outline.mat-form-field-short {
    $padding: 12px;
    $notch-inset: calc($padding - 4px);

    .mdc-text-field--outlined {
        // The border radius will only show up to the size of the notch inset. As such, we need to ensure that the notch inset is at least as large as the border radius.
        --mdc-outlined-text-field-container-shape: #{min($notch-inset, variables.$border-radius)};
        padding-left: $padding;
        padding-right: $padding;

        .mdc-notched-outline {
            .mdc-notched-outline__leading {
                width: $notch-inset;
            }
        
            .mdc-notched-outline__notch {
                max-width: calc(100% - $notch-inset*2);
            }
        }
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0 $padding;
    }

    .mat-mdc-form-field-hint-spacer {
        display: none;
    }
}